<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          :title="title"
          :table-headers="tableHeaders"
          :form-scheme="formScheme"
          :resource="resource"
          :default-table-headers="[]"
          @dialogChange="handleDialog"
          @inputChange="handleChange"
        >
          <template #[`item.name`]="{ item }">
            <div class="pt-2 pb-2">
              <div class="mb-1">
                {{ item.name }}
              </div>
              <div class="grey--text small">
                {{ item.description || '' }}
              </div>
            </div>
          </template>
          <template #[`item.login`]="{ item }">
            <div class="pt-2 pb-2">
              <div
                v-if="item.login"
                class="mb-1"
              >
                <div>
                  {{ item.login }}
                </div>
                <div
                  v-if="!!item.email_address && item.email_address !== item.login"
                  class="grey--text small"
                >
                  {{ item.email_address }}
                </div>
              </div>
              <div
                v-else-if="!item.login && item.email_address"
                class="mb-1"
              >
                <div>
                  {{ item.email_address }}
                </div>
              </div>
            </div>
          </template>
          <template #[`item.url`]="{ item }">
            <div class="pa-2">
              <v-icon
                @click="openWindow(item.url)"
              >
                mdi-search-web
              </v-icon>
            </div>
          </template>
          <template #action-buttons="{ item }">
            <table-btn
              :can="'hosting.providers.auth'"
              :icon="`key-chain-variant`"
              :tooltip="true"
              :tooltip-text="'Get Credentials'"
              :item="item"
              :fn="getCredentials"
            />
          </template>
        </table-data>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Providers',
  metaInfo () {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('hosting.providers.meta.title')} — %s`
    }
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'hosting.providers',
    editedItem: {},
    emails: []
  }),
  computed: {
    getLockedEntities() {
      return this.$store.getters['lockedEntities/getLockedEntities']
    },
    getFoundEmails() {
      return this.$store.getters['emails/emails']
    }
  },
  async mounted () {
    this.title = this.$t('hosting.providers.title')

    let actionsColWight
    if (this.$can('hosting.providers.auth') || this.$can('hosting.providers.update') || this.$can('hosting.providers.remove')) {
      actionsColWight = 20
      actionsColWight += this.$can('hosting.providers.auth') ? 35 : 0
      actionsColWight += this.$can('hosting.providers.update') ? 35 : 0
      actionsColWight += this.$can('hosting.providers.remove') ? 35 : 0
    }

    this.tableHeaders = [
      {
        text: this.$t('hosting.providers.table.name'),
        align: 'start',
        value: 'name'
      },
      { text: this.$t('hosting.providers.table.login'), value: 'login' },
      { text: this.$t('hosting.providers.table.url'), value: 'url', width: '90px', align: 'center', sortable: false },
      {
        text: this.$t('tableData.creationDate'),
        align: 'center',
        value: 'createdAt',
        width: '125px'
      }
    ]

    if (this.$can('hosting.providers.auth') || this.$can('hosting.providers.update') || this.$can('hosting.providers.remove')) {
      this.tableHeaders.push({
        text: this.$t('tableData.actions'),
        align: 'center',
        value: 'actions',
        sortable: false,
        width: `${actionsColWight}px`
      })
    }

    this.formScheme = {
      name: {
        type: 'text',
        label: this.$t('hosting.providers.tableForm.name'),
        createRules: 'req',
        updateRules: []
      },
      url: {
        type: 'text',
        label: this.$t('hosting.providers.tableForm.url'),
        createRules: [],
        updateRules: []
      },
      login: {
        type: 'text',
        label: this.$t('hosting.providers.tableForm.login'),
        createRules: [],
        updateRules: []
      },
      password: {
        type: 'password',
        label: this.$t('hosting.providers.tableForm.password'),
        ref: 'password',
        data: {
          show: false
        },
        createRules: [],
        updateRules: []
      },
      email: {
        type: 'autocomplete',
        label: this.$t('hosting.providers.tableForm.email'),
        items: this.getFoundEmails,
        cacheItems: false,
        // changeFn: async (i, e, v) => {
        //   console.log('i', i)
        //   console.log('e', e)
        //   console.log('v', v)
        // },
        updateSearch: async (i, q, v) => {
          await this.$store.dispatch('emails/getAllEmails', q)
          return false
        },
        md: 12,
        createRules: 'req',
        updateRules: 'req'
      },
      description: {
        type: 'textarea',
        label: this.$t('hosting.providers.tableForm.description'),
        'auto-grow': true,
        rows: 1,
        createRules: [],
        updateRules: [],
        md: 12
      }
    }
    if (this.$currentUser.role === process.env.VUE_APP_MAIN_ROLE || this.$currentUser.global === true) {
      this.tableHeaders = this.$helper.addToArray(this.tableHeaders, {
        text: this.$t('hosting.providers.table.team'),
        value: 'team'
      }, 1)
      this.formScheme = this.$helper.addToObject(this.formScheme, 'affiliate_team_id', {
        type: 'autocomplete',
        label: this.$t('hosting.providers.tableForm.teams'),
        items: await this.$store.dispatch('affiliatesTeams/all'),
        createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
        updateRules: [],
        md: 12
      }, 'notes')
    }

    // if (this.$can('hosting.providers.auth')) {
    //   this.tableHeaders = this.$helper.addToArray(this.tableHeaders, {
    //     text: this.$t('hosting.providers.table.credentials'),
    //     value: 'credentials',
    //     sortable: false,
    //     width: '100px',
    //     align: 'center'
    //   }, 4)
    // }
  },
  methods: {
    openWindow (url) {
      window.open(url, '_blank')
    },
    async getCredentials (item) {
      if (!this.$can('hosting.providers.auth')) {
        return
      }
      const result = await this.$store.dispatch('hostingProviders/getHostingProviderCredential', { id: item.id })
      if (result.id) {
        await this.$store.dispatch('dialog/dialog', {
          show: true,
          title: this.$t('dialog.credentials'),
          contentType: 'code',
          content: JSON.stringify(result, null, 2),
          maxWidth: 500
        })
      }
    },
    async handleDialog(event) {
      await this.$store.dispatch('emails/getAllEmails', event.item.email_address)
    },
    handleChange(changes) {
      this.editedItem = changes
    }
  }
}
</script>

<style lang="scss"></style>
