var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-data',{attrs:{"title":_vm.title,"table-headers":_vm.tableHeaders,"form-scheme":_vm.formScheme,"resource":_vm.resource,"default-table-headers":[]},on:{"dialogChange":_vm.handleDialog,"inputChange":_vm.handleChange},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-2 pb-2"},[_c('div',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"grey--text small"},[_vm._v(" "+_vm._s(item.description || '')+" ")])])]}},{key:"item.login",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-2 pb-2"},[(item.login)?_c('div',{staticClass:"mb-1"},[_c('div',[_vm._v(" "+_vm._s(item.login)+" ")]),(!!item.email_address && item.email_address !== item.login)?_c('div',{staticClass:"grey--text small"},[_vm._v(" "+_vm._s(item.email_address)+" ")]):_vm._e()]):(!item.login && item.email_address)?_c('div',{staticClass:"mb-1"},[_c('div',[_vm._v(" "+_vm._s(item.email_address)+" ")])]):_vm._e()])]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-2"},[_c('v-icon',{on:{"click":function($event){return _vm.openWindow(item.url)}}},[_vm._v(" mdi-search-web ")])],1)]}},{key:"action-buttons",fn:function(ref){
var item = ref.item;
return [_c('table-btn',{attrs:{"can":'hosting.providers.auth',"icon":"key-chain-variant","tooltip":true,"tooltip-text":'Get Credentials',"item":item,"fn":_vm.getCredentials}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }